.App {
  text-align: center;
  --toastify-toast-width: min(100vh, 500px);
}

.App-header {
  background-color: #1C1B1F;
  color: #E6E1E5;
  min-height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

a {
  color: #E6E1E5;
  text-decoration: underline #E6E1E5;
}

.Container-grow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  overflow: hidden;
}

.Toast-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Keyboard-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.5px;
  height: 7vh;
  max-height: 60px;
  border: none;
  border-radius: 6px;
  font-weight: 900;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.Keyboard-button-glowing {
  animation: glowing 1000ms infinite;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 3px #2ba805;
  }
  50% {
    box-shadow: 0 0 10px #49e819;
  }
  100% {
    box-shadow: 0 0 3px #2ba805;
  }
}

table, th, td {
  border: 1px solid #E6E1E5;
  border-collapse: collapse;
  text-align: center;
}

.Letter-table {
  display: flex;
  flex-direction: column;
}

.Letter-row {
  display: flex;
  flex-direction: row;
}

.Letter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12vh;
  height: 12vh;
  max-width: 58px;
  max-height: 58px;
  border-radius: 50%;
  margin: 2px;
  padding: 0;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}

.Letter-empty {
  background-color: #36333b;
  border: 2px solid #938F99;
  color: #E6E1E5;
}

.Letter-guess {
  background-color: #36333b;
  border: 2px solid #E6E1E5;
  color: #E6E1E5;
}

.Letter-invalid {
  background-color: #36333b;
  border: 2px solid #e9bab7;
  color: #e9bab7;
}

.Letter-correct {
  background-color: #559e5d;
  border: 2px solid #559e5d;
  color: #1C1B1F;
}

.Letter-incorrect {
  background-color: #a3a3a3;
  border: 2px solid #a3a3a3;
  color: #912d23;
}

.Letter-elsewhere {
  background-color: #b3ae4b;
  border: 2px solid #b3ae4b;
  color: #1C1B1F;
}

.Letter-count-hint {
  position: absolute;
  bottom: 16%;
  right: 16%;
  color: #1C1B1F;
  font-size: 14px;
  font-weight: 700;
}
